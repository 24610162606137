<template>
	<v-card>
		<v-container fluid>
			<v-row v-if="!loading">
				<v-col cols="12" lg="3" md="6">
					<tarjeta-datos
						header
						titulo="Nombre/Datos Fiscales"
						icon="account-group"
						:datos="[
							{ nombre: 'Colectivo', valor: datos.Colectivo },
							{ nombre: 'Razón Social', valor: datos.RazonSocial },
							{ nombre: 'Identificador', valor: datos.Identidad },
							{ nombre: 'Nombre', valor: datos.Nombre },
							{ nombre: 'Apellido1', valor: datos.Apellido1 },
							{ nombre: 'Apellido2', valor: datos.Apellido2 },
						]"
					></tarjeta-datos>
				</v-col>
				<v-col cols="12" md="12" lg="6">
					<tarjeta-datos
						header
						titulo="Nombre/Datos Fiscales"
						icon="account-group"
						:datos="[
							{ nombre: 'Ciudad', valor: datos.TextoCiudad },
							{ nombre: 'Calle', valor: datos.NombreCalle },
						]"
					>
						<v-divider />
						<v-simple-table>
							<tbody>
								<tr>
									<th>Número</th>
									<td>{{ datos.Numero }}</td>
									<th>Tipo Nº</th>
									<td>{{ datos.TextoNumero }}</td>
									<th>Tipo Calif</th>
									<td>{{ datos.TextoCalificador }}</td>
									<th>Bloq.</th>
									<td>{{ datos.TextoBloque }}</td>
								</tr>
								<tr>
									<th>Portal</th>
									<td>{{ datos.TextoPortal }}</td>
									<th>Escalera</th>
									<td>{{ datos.TextoEscalera }}</td>
									<th>Piso</th>
									<td>{{ datos.TextoPiso }}</td>
									<th>Puerta</th>
									<td>{{ datos.TextoPuerta }}</td>
								</tr>
								<tr>
									<th>Aclarador</th>
									<td>{{ datos.Aclarador }}</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</v-simple-table>
					</tarjeta-datos>
				</v-col>
				<v-col cols="12" lg="3" md="6">
					<tarjeta-datos
						header
						titulo="Varios"
						icon="chevron-double-down"
						:datos="[
							{ nombre: 'Idioma', valor: datos.TextoIdioma },
							{ nombre: 'Tipo Impuesto', valor: datos.TextoImpuesto },
							{ nombre: 'Tipo Impresión', valor: datos.TipoImprimir },
							{ nombre: 'Código Contable', valor: datos.CodigoContable },
							{ nombre: 'Grupo Cobro', valor: datos.TextoGrupo },
							{ nombre: 'Observaciones', valor: datos.Observaciones },
						]"
					></tarjeta-datos>
				</v-col>
				<v-col cols="12" lg="3" md="6" v-show="datos.RazonSocial">
					<tarjeta-datos
						header
						titulo="Representante"
						icon="account"
						:datos="[
							{
								nombre: 'Colectivo Representante',
								valor: datos.ColectivoRepresentante,
							},
							{
								nombre: 'Identificador Representante',
								valor: datos.IdentificadorRep,
							},
							{ nombre: 'Nombre Representante', valor: datos.Representante },
							{ nombre: 'Comentario Representante', valor: datos.Comentario },
						]"
					></tarjeta-datos>
				</v-col>
				<v-col cols="12" lg="6" md="6" v-if="$root.acceso('CONTROL_IMPAGADOS')">
					<tarjeta-datos header icon="account">
						<template v-slot:titulo>
							Notas Cliente
							<v-btn class="ml-auto" small color="primary" @click.stop="getMessages">
								<v-icon left>mdi-refresh</v-icon>Recargar
							</v-btn>
						</template>
						<v-timeline dense>
							<v-slide-x-transition group>
								<mensaje-timeline
									v-for="(message, i) in mensajesCliente"
									:message="message"
									:key="i"
									small
									@reload="getMessages"
								></mensaje-timeline>
								<nuevo-mensaje-timeline
									:key="newMessage.show"
									v-show="newMessage.show"
									v-model="newMessage"
								/>
							</v-slide-x-transition>
						</v-timeline>
						<v-btn
							v-if="!newMessage.show"
							class="ml-2 mb-2"
							small
							color="primary"
							@click.stop="newMessage.show = true"
						>
							<v-icon left>mdi-plus</v-icon>nuevo mensaje
						</v-btn>
						<template v-else>
							<v-btn
								:disabled="[newMessage.msg, $root.acceso('CONTROL_IMPAGADOS') ? newMessage.tipo : true].some(x => !x)"
								class="ml-2 mb-2"
								small
								color="primary"
								@click.stop="sendNewMessage"
							>
								<v-icon left>mdi-send</v-icon>enviar
							</v-btn>
							<v-btn
								class="ml-2 mb-2"
								small
								color="error"
								@click.stop="newMessage.show = false; newMessage.msg = null"
							>
								<v-icon left>mdi-close</v-icon>cancelar
							</v-btn>
						</template>
					</tarjeta-datos>
				</v-col>
				<v-col cols="12">
					<v-card outlined>
						<v-tabs
							show-arrows
							background-:color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
							v-model="tab"
						>
							<v-tab>Envío</v-tab>
							<v-tab>Contactos</v-tab>
							<v-tab>Cobro</v-tab>
							<v-tab v-if="$root.acceso('VER_CONTRATOS')" @click.once="getContratos">Contratos</v-tab>
						</v-tabs>
						<v-tabs-items touchless v-model="tab">
							<v-tab-item>
								<v-simple-table>
									<thead>
										<tr>
											<th class="secondary--text">Nombre</th>
											<th class="secondary--text">Dirección Envío</th>
											<th class="secondary--text">Ciudad Envío</th>
											<th class="secondary--text">Número</th>
											<th class="secondary--text">Aclarador</th>
											<th class="secondary--text">Cod Postal</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="i" v-for="(envio, i) in datos.ClienteEnvio">
											<td>{{ envio.NombreE }}</td>
											<td>{{ envio.DireccionCUPS }}</td>
											<td>{{ envio.CiudadCUPS }}</td>
											<td>{{ envio.NumeroE }}</td>
											<td>{{ envio.AclaradorE }}</td>
											<td>{{ envio.CodPostalE }}</td>
										</tr>
									</tbody>
								</v-simple-table>
							</v-tab-item>
							<v-tab-item>
								<v-simple-table>
									<thead>
										<tr>
											<th class="secondary--text">Tipo</th>
											<th class="secondary--text">Valor</th>
											<th class="secondary--text">Contacto</th>
											<th class="secondary--text">Departamento</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="i" v-for="(envio, i) in datos.ClienteContacto">
											<td>{{ tipoContacto(envio.TipoContacto) }}</td>
											<td>{{ envio.Valor }}</td>
											<td>{{ envio.Contacto }}</td>
											<td>{{ envio.Departamente }}</td>
										</tr>
									</tbody>
								</v-simple-table>
							</v-tab-item>
							<v-tab-item>
								<v-simple-table>
									<thead>
										<tr>
											<th class="secondary--text">Nombre</th>
											<th class="secondary--text">Colectivo</th>
											<th class="secondary--text">Identificador</th>
											<th class="secondary--text">Tipo Cobro</th>
											<th class="secondary--text">IBAN</th>
											<th class="secondary--text">Banco</th>
											<th class="secondary--text">BIC</th>
											<th class="secondary--text">Libre</th>
											<th class="secondary--text">Dia Cobro</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="i" v-for="(envio, i) in datos.ClientePago">
											<td>{{ envio.NombreP }}</td>
											<td>{{ envio.Colectivo }}</td>
											<td>{{ envio.IdentidadPago }}</td>
											<td>{{ envio.TipoCobro }}</td>
											<td>{{ envio.IBAN }}</td>
											<td>{{ envio.Banco }}</td>
											<td>{{ envio.Bic }}</td>
											<td>{{ envio.IsIbanLibre ? "Sí" : "No" }}</td>
											<td>{{ envio.DiaPago }}</td>
										</tr>
									</tbody>
								</v-simple-table>
							</v-tab-item>
							<v-tab-item v-if="$root.acceso('VER_CONTRATOS')">
								<v-progress-linear v-if="!isContratos" indeterminate></v-progress-linear>
								<v-simple-table :key="isContratos">
									<thead>
										<tr>
											<th class="secondary--text">Estado</th>
											<th class="secondary--text">Contrato</th>
											<th class="secondary--text">CUPS</th>
											<th class="secondary--text">Producto</th>
											<th class="secondary--text">Tarifa</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in datos.contratos" :key="item.IdContrato">
											<td>
												<status-chip :value="item.Situacion" />
											</td>
											<td>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															v-on="on"
															v-bind="attrs"
															link
															:to="`/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`"
															color="secondary"
															text
														>{{ Math.abs(Number(item.CodigoContrato)) }}</v-btn>
													</template>
													<span>Detalles del contrato</span>
												</v-tooltip>
											</td>
											<td>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															v-on="on"
															v-bind="attrs"
															link
															:to="`/detalles_cups?cups=${item.CodigoCUPS}`"
															color="secondary"
															text
														>{{ item.CodigoCUPS }}</v-btn>
													</template>
													<span>Detalles del CUPS</span>
												</v-tooltip>
											</td>
											<td>
												<span v-text="item.TarifaGrupo" />
											</td>
											<td>
												<v-chip v-show="item.Tarifa" label small v-text="item.Tarifa" />
											</td>
										</tr>
									</tbody>
								</v-simple-table>
							</v-tab-item>
						</v-tabs-items>
					</v-card>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-overlay absolute>
					<v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
				</v-overlay>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import { processToken } from "@/utils/index.js";

export default {
	components: {
		TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
		MensajeTimeline: () => import("@/components/MensajeTimeline.vue"),
		NuevoMensajeTimeline: () => import("@/components/NuevoMensajeTimeline.vue"),
		TextEditor: () => import("@/components/TextEditor.vue"),
	},
	props: {
		_idCliente: String | Number
	},
	data() {
		return {
			datos: null,
			loading: true,
			tab: 0,
			isContratos: false,
			idCliente: this._idCliente || this.$route.query.idCliente,
			mensajesCliente: [],
			newMessage: {
				show: false,
				files: [],
				msg: null,
			},
		};
	},
	async mounted() {
		let str = this.$store.getters.getDatosEmpresa;

		const { IdUsuario } = str.iss;
		const IdEmpresa = this.$store.getters.getIdEmpresa;

		let { data } = await axios({
			method: "get",
			url: `${process.env.VUE_APP_API_URL}/Cliente/GetCliente/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Cliente/${this.idCliente}`,
		});
		this.datos = processToken(data).iss;
		this.getMessages();
		this.loading = false;
	},
	methods: {
		getContratos() {
			let str = this.$store.getters.getDatosEmpresa;

			const { IdAgente } = str.iss;
			axios({
				method: "get",
				// url: `${process.env.VUE_APP_API_URL}/Cliente/GetCliente/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Cliente/${this.$route.query.idCliente}`,
				url: `${process.env.VUE_APP_OUR_API_URL}/contratosVerificados.php`,
				params: { IdAgente }
			}).then(({ data }) => {
				this.datos.contratos = data.filter(
					(c) => c.IdCliente == this.idCliente
				);
				this.isContratos = true;
			});
		},
		async getMessages() {
			let { data: messages } = await axios({
				method: 'GET',
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
				params: {
					IdCliente: this.idCliente,
					token: this.$store.getters.getJwtEmpresa
				}
			})

			const list_to_tree = (list) => {
				let map = {}, roots = [], i;

				for (i = 0; i < list.length; i += 1) {
					map[list[i].idMensaje] = i; // initialize the map
					list[i].children = []; // initialize the children
				}

				list.forEach(node => {
					if (node.idParent && node.idParent != '0') {
						// if you have dangling branches check that map[node.parentId] exists
						list[map[node.idParent]].children.push(node);
					} else {
						roots.push(node);
					}
				})

				return roots;
			}

			this.mensajesCliente = list_to_tree(messages)

		},
		sendNewMessage() {
			const formData = new FormData();
			formData.append('token', this.$store.getters.getJwtEmpresa);
			formData.append('IdCliente', this.idCliente);
			formData.append('mensaje', this.newMessage.msg);
			formData.append('tipo', this.newMessage.tipo);
			for (let i = 0; i < this.newMessage.files.length; i++) {
				formData.append("documentos[]", this.newMessage.files[i]);
			}
			axios({
				method: "POST",
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
				data: formData,
			})
				.then(({ data }) => {
					this.newMessage.show = false;
					this.newMessage.msg = "";
					this.newMessage.files = [];
					this.getMessages();
				})
				.catch((err) => console.error(err));
		},
		tipoContacto(val) {
			switch (val) {
				case 'M':
					return 'Móvil'
				case 'E':
					return 'Email'
				case 'F':
					return 'Fijo'
				default:
					return '';
			}
		}
	},
};
</script>